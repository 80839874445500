import React, { Fragment, useState } from "react"
import PropTypes from "prop-types"
import "../assets/css/feature.scss"
import CTA from "./cta"
import { FixedImage } from "../services/images"

import Details from "./details"

const FeatureBlock = ({ data, anchor }) => {
  let [showDetails, setShowDetails] = useState(false)
  let classList = ["text"]
  if (data.backgroundColor != null) {
    classList.push(data.backgroundColor)
  }

  if (data.full) {
    classList.push("full")
  }

  let cta
  if (data.cta && (data.cta.link || data.cta.externalLink)) {
    cta = (
      <div className="mt-lg">
        <CTA data={data.cta} />
      </div>
    )
  }

  let title
  if (data.title) {
    title = <h2>{data.title}</h2>
  }

  if ((!data.text || data.text === "") && data.blockType !== "text") {
    if (!data.image) {
      data.image = { image: {} }
    }
    return (
      <div className="image">
        <FixedImage
          src={data.image.image.id}
          alt={data.title}
          options={{ gravity: data.image.gravity || "top" }}
        />
      </div>
    )
  } else {
    if (data.image && data.image.image) {
      classList.push("display-flex")
    }

    const RenderTitle = () => {
      if (!data.full) {
        return (
          <Fragment>
            {title}
            {data.icon && data.icon.image && (
              <img className="icon" src={data.icon.image.publicUrl} />
            )}
            {data.subTitle && (
              <div
                className="sub-title"
                dangerouslySetInnerHTML={{ __html: data.subTitle }}
              ></div>
            )}
          </Fragment>
        )
      } else {
        if (!anchor || anchor !== "guarantee") {
          return (
            <Fragment>
              {title}
              {data.icon && <img className="icon" src={data.icon} />}
              <div className={`divider ${data.subTitle ? "mb-sm" : ""}`}></div>
              {data.subTitle && <h3>{data.subTitle}</h3>}
            </Fragment>
          )
        } else {
          return (
            <Fragment>
              {data.subTitle && <h3>{data.subTitle}</h3>}
              <div className={`divider ${data.subTitle ? "mb-sm" : ""}`}></div>
              {title}
            </Fragment>
          )
        }
      }
    }

    let hasImage = data.image && data.image.image && data.image.image.publicUrl

    if (data.details && data.details.length > 0) {
      classList.push("align-top")
    }

    return (
      <div className={classList.join(" ")}>
        <div className={`title ${anchor === "guarantee" ? "g" : ""}`}>
          {!hasImage && RenderTitle()}
        </div>
        <div className={`content ${hasImage ? "text-and-image" : ""}`}>
          <div className={hasImage ? "half" : ""}>
            {hasImage && <h3>{data.title}</h3>}
            <div dangerouslySetInnerHTML={{ __html: data.text }}></div>
          </div>
          {hasImage ? (
            <FixedImage src={data.image.image.id} alt={data.title} />
          ) : (
            ""
          )}
          {data.details && data.details.length > 0 && (
            <span
              className="join"
              onClick={() => {
                setShowDetails(!showDetails)
              }}
            >
              {!showDetails ? "See more benefits" : "Show less"}
            </span>
          )}
          {cta}
        </div>
        {data.details && showDetails && data.details.length > 0 && (
          <Details data={data.details} />
        )}
      </div>
    )
  }
}

FeatureBlock.defaultProps = {
  data: {},
}

FeatureBlock.propTypes = {
  data: PropTypes.object,
}

export default FeatureBlock
