import React from "react"
import PropTypes from "prop-types"
import "../assets/css/feature.scss"
import FeatureBlock from "./featureBlock"
import Markdown from "markdown-to-jsx"
import CTA from "./cta"
import { FixedImage } from "../services/images"

const renderTitle = data => {
  if (data.title && data.anchor !== "guarantee") {
    return (
      <div className="header">
        <h2 className={data.blocks.length == 0 ? "no-divider" : ""}>
          {data.title}
        </h2>
        {data.blocks.length == 0 && <div className="divider mx-auto"></div>}
      </div>
    )
  }
}

const renderDescription = data => {
  if (data.body || data.text) {
    return (
      <div
        className="description"
        dangerouslySetInnerHTML={{ __html: data.body || data.text }}
      ></div>
    )
  }
}

const Feature = ({ data, side, main, condensed = false }) => {
  let classList = ["feature"]
  if (main) {
    classList.push("main")
  }
  if (data.full || data.blocks.length === 1) {
    classList.push("full")
  }
  if (!data.full) {
    if (data.imageSide) {
      classList.push(data.imageSide)
    } else {
      classList.push(side)
    }
  }

  let cta
  if (data.cta) {
    cta = (
      <div className="talign-center py-md mt-sm">
        <CTA data={data.cta} color="light" />
      </div>
    )
  }

  if (data.backgroundColor) {
    classList.push(data.backgroundColor)
    classList.push("py-sm")
  } else if (data.full) {
    classList.push("white")
  }

  if (!data.blocks) {
    data.blocks = []
  }

  return (
    <div className={classList.join(" ")} id={data.anchor}>
      {renderTitle(data)}
      {renderDescription(data)}
      {data.blocks.length > 0 && (
        <section
          className={`blocks ${data.blocks.length === 3 ? "thirds" : ""} ${
            condensed ? "condensed" : ""
          }`}
        >
          {data.blocks.map(block => (
            <FeatureBlock data={block} key={block.id} anchor={data.anchor} />
          ))}
          {data.blocks.length == 0 && data.image && data.image.image.id && (
            <div className="image full flavor">
              <FixedImage
                src={data.image.image.id}
                alt={data.title || data.name}
              />
            </div>
          )}
        </section>
      )}
      {data.blocks.length == 0 && data.image && data.image.image && (
        <div className="image full flavor">
          <FixedImage src={data.image.image.id} alt={data.title || data.name} />
        </div>
      )}
      {cta && <div className="text-align-center mt-n pb-md">{cta}</div>}
    </div>
  )
}

Feature.defaultProps = {
  data: {},
  side: "left",
  main: false,
}

Feature.propTypes = {
  data: PropTypes.object,
  side: PropTypes.string,
  main: PropTypes.bool,
}

export default Feature

/*let classList = ["feature", side]
  if (main) {
    classList.push("main")
  }

  if (data.backgroundColor) {
    classList.push(data.backgroundColor)
  } else {
    classList.push("light")
  }

  if (!data.image) {
    data.image = {}
  }

  let textImage = <div></div>
  if (data.textImage && data.textImage.publicURL) {
    textImage = <img src={data.textImage.publicURL} />
  }

  if (!data.text) {
    data.text = ""
  }

  let cta = <div></div>
  if (data.cta) {
    cta = (
      <div className="mt-sm">
        <CTA data={data.cta} />
      </div>
    )
  }

  if (!data.allText) {
    return (
      <div className={classList.join(" ")}>
        <div className="text">
          <h2>{data.title}</h2>
          <div className="content">
            <Markdown>{data.text}</Markdown>
            {textImage}
            {cta}
          </div>
        </div>

        <div className="image">
          <picture>
            <img src={data.image.publicURL} alt={data.title} />
          </picture>
        </div>
      </div>
    )
  } else {
    return (
      <div className={classList.join(" ")}>
        <div className="text">
          <h2>{data.title}</h2>
          <div className="content">
            <Markdown>{data.text}</Markdown>
            {textImage}
          </div>
        </div>

        <div className="text">
          <div className="content">
            <Markdown>{data.secondText}</Markdown>
            {cta}
          </div>
        </div>
      </div>
    )
  }*/
