import { useEffect } from "react"

export function useAnchor() {
  useEffect(() => {
    if (window.location.hash) {
      let anchor = document.querySelector(window.location.hash)
      if (anchor) {
        anchor.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        })
      }
    }
    return () => {}
  }, [])
}
