import React, { useEffect, useRef } from "react"
import PropTypes from "prop-types"
import "../assets/css/hero.scss"
import { FixedImage } from "../services/images"
import CTA from "./cta"

const Hero = ({ data, title, className = "", options = {}, tagline }) => {
  let background = ""
  if (data && data.image) {
    background = data.image.image.id
    if (data.image.gravity) {
      options.gravity = data.image.gravity
    }
  }

  let ref = useRef()
  const handleScroll = () => {
    let max = 0
    let min = 1
    let depth = 200
    if (ref.current) {
      if (window.scrollY > depth) {
        ref.current.style.opacity = max
      } else {
        let ratio = window.scrollY / depth
        ref.current.style.opacity = 1 - ratio
      }
    }
  }
  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return function() {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])
  if (background) {
    options.face = true
    return (
      <section className={`hero ${className}`}>
        <FixedImage src={background} options={options} />
        {title != "" && !className.includes("landing") && (
          <div className="text">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 71.11 59.06"
              className="logo"
              alt="The Stīl Trust"
            >
              <defs>
                <clipPath id="a" transform="translate(-.6 -.94)">
                  <path fill="none" d="M0 .94h78.96v59H0z" />
                </clipPath>
              </defs>
              <path
                d="M23.79 27.11c.18-3.06-1.69-3.71-3.53-3.71a2.94 2.94 0 00-3.1 2.77c0 3.21 8.43 4.93 8.43 9.43 0 3.53-3.71 4.75-6.34 4.75a8.21 8.21 0 01-5-1.54l.18-4h.72c.36 3.27 2 4.46 4.54 4.46a3 3 0 003.09-2.63c0-4.43-8.42-5.79-8.42-9.93 0-3.06 3.17-4.39 6-4.39a8.29 8.29 0 014.35 1.15l-.21 3.6h-.72M44.59 22.72l-.1 3.52v9.87c0 2.66.21 3.17 2.63 3.17v.71h-8.36v-.71c2.67 0 2.88-.51 2.88-3.17v-9.33c0-2.66 0-2.87-2.7-2.87h-7.09v10.58c0 4.5 1.95 5.32 5.47 3.85l.36.65c-8.31 4.21-8.67-2.49-8.67-3.75V23.91h-2.16v-1c2.84 0 3.78-1.33 4.64-4.4h.36v4.22h12.74M56.91 39.99h-8.35v-.71c2.66 0 2.88-.51 2.88-3.17V18.5c0-2.66 0-3-2.7-3v-.72l5.54-.36v21.69c0 2.66.22 3.17 2.63 3.17v.71"
                fill="#fff"
              />
              <path
                fill="#fff"
                stroke="#fff"
                stroke-miterlimit="10"
                stroke-width="1.5"
                d="M37.86 18.51h9.28"
              />
              <path
                d="M4.58 40.46h-2.3l8-26.21h2.3zM63.08 40.46h-2.3l8-26.21h2.3z"
                fill="#fff"
              />
              <g clip-path="url(#a)" fill="#fff">
                <path d="M4.14 59.06a2.35 2.35 0 01-2.58-2.53v-6H.23v-.87h1.33v-2.93h.84v2.93h3.27v.81H2.4v5.93a1.61 1.61 0 001.84 1.78 3 3 0 001.35-.33v.83a3.33 3.33 0 01-1.45.38M20.79 50.49c-2.13 0-4 1.6-4 4.59v3.76h-.89v-9.18h.89v2.61a4.34 4.34 0 014.07-2.75v1h-.09M37.58 58.84v-1.71a3.6 3.6 0 01-3.31 1.93 3.5 3.5 0 01-3.68-3.78v-5.62h.89v5.46a2.8 2.8 0 002.92 3.13 3.15 3.15 0 003.19-3.28v-5.31h.88v9.18h-.88M52.4 59.06a6.29 6.29 0 01-3.76-1.33l.52-.7a5.41 5.41 0 003.29 1.21c1.25 0 2.22-.69 2.22-1.75 0-1.08-1.15-1.47-2.43-1.83-1.5-.44-3.15-.89-3.15-2.52 0-1.48 1.24-2.54 3-2.54a6.22 6.22 0 013.26 1l-.47.74a5.23 5.23 0 00-2.83-.92c-1.26 0-2.08.68-2.08 1.6 0 1 1.24 1.4 2.55 1.78 1.48.41 3 .95 3 2.58.08 1.6-1.33 2.68-3.12 2.68M68.93 59.06a2.35 2.35 0 01-2.53-2.58v-6h-1.37v-.82h1.37v-2.93h.88v2.93h3.23v.81h-3.27v5.93a1.61 1.61 0 001.84 1.78 3 3 0 001.32-.33v.83a3.34 3.34 0 01-1.5.33M2.82 9.6A1.69 1.69 0 01.96 7.78V3.44H0v-.58h1V.74h.6v2.12h2.32v.58H1.6v4.28A1.16 1.16 0 002.92 9a2.21 2.21 0 001-.23v.6a2.38 2.38 0 01-1.08.23M12.27 9.49V5.55a2 2 0 00-2.11-2.27 2.27 2.27 0 00-2.3 2.37v3.84h-.64V0h.64v4.06a2.6 2.6 0 012.39-1.39 2.53 2.53 0 012.66 2.73v4.06h-.64" />
              </g>
              <path
                d="M19.4 9.06a3 3 0 002.28-1l.44.39a3.49 3.49 0 01-2.72 1.19 3.29 3.29 0 01-3.25-3.46A3.26 3.26 0 0119.3 2.7c1.86 0 3 1.51 3 3.47a1.85 1.85 0 010 .23h-5.5a2.63 2.63 0 002.6 2.66m2.26-3.2a2.47 2.47 0 00-2.38-2.58 2.59 2.59 0 00-2.45 2.58z"
                fill="#fff"
              />
            </svg>
            <h1>{title}</h1>
            <div className="divider"></div>
            {data.text && <div className="description">{data.text}</div>}
            {data.cta && (
              <div className="hero-cta mt-sm">
                <CTA data={data.cta} color="light" />
              </div>
            )}
          </div>
        )}
        {className.includes("landing") && (
          <div className="text">
            {title && <h1>{title}</h1>}
            {data.cta && (
              <div className="hero-cta mt-sm">
                <CTA data={data.cta} color="light" />
              </div>
            )}
          </div>
        )}
        {tagline && <h2 ref={ref}>{tagline}</h2>}
      </section>
    )
  }
  return <div></div>
}

Hero.defaultProps = {
  data: {},
  title: "",
}

Hero.propTypes = {
  data: PropTypes.object,
  title: PropTypes.string,
}

export default Hero
