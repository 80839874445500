import React from "react"
import Detail from "./detail"
import "../assets/css/details.scss"

export default ({ data = [] }) => {
  return (
    <section className="details">
      {data.length > 0 &&
        data.map(detail => {
          return <Detail data={detail} />
        })}
    </section>
  )
}
