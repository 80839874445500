import React, { useEffect, useRef } from "react"
import "../assets/css/overlayText.scss"

import arrow from "../../static/down-arrow-dark.png"

export default ({ children, className }) => {
  let ref = useRef()
  const handleScroll = () => {
    let max = 0.9
    let min = 0.65
    let depth = 200
    if (window.scrollY > depth) {
      if (ref.current) {
        ref.current.style.backgroundColor = `rgba(255, 255, 255, ${max})`
      }
    } else {
      if (ref.current) {
        let ratio = window.scrollY / depth
        if (ratio < min) {
          ratio = min
        }

        if (ratio > max) {
          ratio = max
        }
        ref.current.style.backgroundColor = `rgba(255, 255, 255, ${ratio})`
      }
    }
  }
  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return function() {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])
  return (
    <section ref={ref} className={`overlay ${className}`}>
      <div className="talign-center arrow">
        <img alt="Scroll Down" src={arrow} />
      </div>
      {children}
    </section>
  )
}
