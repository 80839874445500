import React from "react"
import PropTypes from "prop-types"
import Feature from "./feature"
import "../assets/css/feature.scss"

const Features = ({ features, startSide }) => {
  let side = startSide
  return (
    <div className="features">
      {features.map((feature, i) => {
        if (side === "left") {
          side = "right"
        } else {
          side = "left"
        }
        return <Feature data={feature} side={side} key={`key-${i}`} />
      })}
    </div>
  )
}

Features.defaultProps = {
  features: [],
  side: "right",
}

Features.propTypes = {
  features: PropTypes.array,
  side: PropTypes.string,
}

export default Features
