import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Hero from "../components/hero"
import Features from "../components/features"
import FullCTA from "../components/fullCTA"
import { BodyText } from "../components/bodyText"
import OverlayText from "../components/overlayText"
import { Sort } from "../services/utils"
import { useAnchor } from "../effects/anchor.effect"

const Membership = ({ data }) => {
  const article = data.tst.Page || {}
  if (!article.pageMetaData) {
    article.pageMetaData = {}
  }
  const features = Sort(article.features, article.featuresSort)
  const ctas = Sort(article.ctas, article.ctasSort)
  article.pageMetaData.url = article.url
  useAnchor()
  return (
    <Layout meta={article.pageMetaData || { title: article.name }}>
      <Hero data={article.hero} className="fixed" />
      <OverlayText>
        <BodyText article={article} />
        <Features features={features} startSide="left" />
        {ctas &&
          ctas.length > 0 &&
          ctas.map((cta, idx) => <FullCTA data={cta} key={`m-${idx}`} />)}
      </OverlayText>
    </Layout>
  )
}

export default Membership

export const query = graphql`
  query MembershipQuery($id: ID!) {
    tst {
      Page(where: { id: $id }) {
        name
        pageTitle
        text
        url
        ctas {
          ...CTA
        }
        ctasSort
        features {
          ...Feature
        }
        featuresSort
        hero {
          ...Hero
        }
        pageMetaData {
          ...Meta
        }
      }
    }
  }
`
