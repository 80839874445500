import React, { useState } from "react"
import "../assets/css/details.scss"

export default ({ data = {} }) => {
  let [state, setState] = useState("closed")
  const toggleState = () => {
    if (state === "closed") {
      setState("open")
    } else {
      setState("closed")
    }
  }
  return (
    <div className={`detail ${data.text ? "has-learn-more" : ""}`}>
      <span class="detail-text">{data.title}</span>
      {data.text ? (
        <span className="learn-more" onClick={toggleState}>
          {state == "open" ? "show less" : "learn more"}
        </span>
      ) : (
        ""
      )}
      {state == "open" && (
        <div
          className="text"
          dangerouslySetInnerHTML={{ __html: data.text }}
        ></div>
      )}
    </div>
  )
}
